.container {
    display: flex;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    align-items: center;
    justify-content: center;
}

.wrapper {
    width: 92%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: center;
    background-color: "red";
}

.logoImage {
    width: 100%;
    max-width: 100%;
    height: auto;
}

.authButton {
    background-color: #F16623;
    color: #FFFFFF;
    border-radius: 5px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    text-decoration: none;
    margin-top: 10px;
}

.btnEmpty {
    background-color: transparent;
    border-color: #F79C5D;
    border-width: 2px;
    border-style: solid;
}