/* styles.css */

.loadingContainer {
    flex: 1;
    justify-content: center;
    align-items: center;
}

.container {
    flex: 1;
    width: 100%;
    height: 100%;
    background-color: #fff;
}

.topWrapper {
    width: 92%;
    margin-left: auto;
    margin-right: auto;
}

.listingsWrapper {
    flex: 1;
    width: 92%;
    margin-left: auto;
    margin-right: auto;
}

.topArea {
    align-self: stretch;
}

.searchAndCategoriesBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    align-self: stretch;
    overflow-x: auto;
    overflow-y: hidden;
    margin-top: 10px;
    margin-bottom: 10px;
}

.businessListings {
    align-self: stretch;
    padding-bottom: 8rem;
}

.searchArea {
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.searchInput {
    background-color: #eeeeee;
    border-radius: 10px;
    padding: 10px;
    font-size: 20px;
    color: gray;
    flex: 7;
}

.mapButtonArea {
    margin-left: auto;
    flex: 1.3;
    background-color: black;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
}

.bottomNavBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: auto;
    position: fixed;
    bottom: 0;
    background: white;
    border-top: 1px solid grey;
}

.bottomNavBar button {
    background: none;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
}

.bottomNavBar p {
    font-size: 1rem;
    color: #F16623 !important;
    padding: 0;
    margin: 0;
}


.bottomNavBar .bottomBarButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 5px 0;
}

.bottomNavBar i {
    padding: 0 !important;
    margin: 0 !important;
    font-size: 1.5rem;
    color: #F16623 !important;
}