body,
html {
    background-color: #f5f5f5;
    height: 100%;

}

.navbar {
    width: 100%;
    display: flex;
    background: white;
}

.logoWrapper,
.menuElementsWrapper {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.logoWrapper {
    flex: 1;
    text-align: center;
}

.logoWrapper img {
    display: block;
    width: 30%;
    height: auto;
    margin: auto;
}

.bar {
    display: none;
    cursor: pointer;
}

.menuElementsWrapper {
    flex: 1.5;
    display: flex;
    justify-content: space-evenly;
}

.menuElement {
    display: flex;
    align-self: center;
}

.menuElement a {
    color: black;
    text-decoration: none;
    cursor: pointer;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: bold;
}

.info {
    width: 80%;
    height: 90vh;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-self: center;
    justify-self: center;
}

.texts,
.photoWrapper {
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.texts {
    text-align: left;
    align-self: center;
    padding-left: 1rem;
    padding-right: 1rem;
}

.texts h1 {
    font-size: 4rem;
}

.texts p {
    font-size: 1.3rem;
}

.photoWrapper {
    align-self: center;
}

.photoWrapper img {
    max-width: 130%;
    height: auto;
    justify-self: center;
    align-self: center;
}

.buttons {
    display: flex;
}

.buttons img {
    max-width: 40%;
    height: auto;
    margin: 10px;
}

.buttons img:first-child {
    margin-left: 0;
}


/* Modal Styles */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: rgba(0, 0, 0, 0.5); */
    /* Semi-transparent background */
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    z-index: 1000;
}

.modal {
    background: white;
    border: 1px solid black;
    border-radius: 10px;
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    padding: 2vh;
}

.modal h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
}

.modal p {
    font-size: 1rem;
    margin-bottom: 20px;
}

.closeButton {
    cursor: pointer;
    background: none;
    float: right;
    background: red;
    color: white;
    width: 30px;
    height: 30px;
    font-weight: bold;
    border-radius: 100%;
}

.modal form {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    padding: 0;
}

.modal label {
    margin-bottom: 5px;
}

.modal input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.modal button {
    background-color: #F16623;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
}

/* Media Queries for Responsive Design */
@media screen and (max-width: 768px) {
    .navbar {
        flex-direction: column;
        align-items: center;
    }

    .navbar .logoWrapper {
        order: 1;
    }

    .navbar .bar {
        order: 2;
        display: block;
        text-align: right;
        font-size: 3vh;
        margin-bottom: 2vh;
    }


    .menuElementsWrapper {
        display: none;
        flex-basis: 100%;
        justify-content: space-around;
    }

    .menuElementsWrapper.active {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .menuElementsWrapper.active .menuElement a {
        font-size: 2.5vh;
        line-height: 5vh;
    }

    .info {
        flex-direction: column-reverse;
    }

    .texts {
        order: 3;
        text-align: center !important;
    }

    .texts .buttons {
        justify-content: center;
    }

    .photoWrapper {
        order: 4;
    }
}