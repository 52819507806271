/* PrivacyPolicyPage.css */
.policyContainer {
    max-width: 800px;
    margin: 0 auto;
    margin-left: 10px;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    color: #333;
}

h1 {
    color: #000;
    font-size: 24px;
    font-weight: bold;
}

h2 {
    color: #000;
    font-size: 18px;
    font-weight: bold;
}

section {
    margin-bottom: 20px;
}

p {
    font-size: 14px;
}

a {
    color: #0066cc;
}