/* style.module.css */
.wrapper {
    display: flex;
}

.sidebarContainer {
    flex: 1;
}

.mainContent {
    flex: 3;
    padding: 20px;
}

.form {
    margin: 20px 0;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.input {
    display: block;
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.itemContainer, .categoryContainer, .setContainer {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 0;
}

.addButton, .saveButton {
    padding: 10px 20px;
    margin: 10px 5px;
    border: none;
    border-radius: 5px;
    background-color: #28a745;
    color: white;
    cursor: pointer;
}

.addButton:hover, .saveButton:hover {
    background-color: #218838;
}

.services {
    margin-top: 20px;
}

.image {
    max-width: 100px;
    max-height: 100px;
    display: block;
    margin: 10px 0;
}
