/* style.module.css */
body {
    background: white;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: white;
    text-align: center;
}

.loginContainer {
    padding: 20px;
    border-radius: 10px;
    align-items: center;
}

.textLeft {
    text-align: left;
    float: left;
}

.title {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: bold;
}

.input {
    width: 100%;
    height: 40px;
    border: 1px solid gray;
    border-radius: 5px;
    padding-left: 10px;
    margin-bottom: 10px;
}

.loginButton {
    background-color: #F16623;
    color: #FFFFFF;
    border-radius: 5px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    margin-top: 10px;
    border: none;
    cursor: pointer;
}

.registerLink {
    color: #007bff;
    margin-top: 10px;
    text-decoration: underline;
}

.errorModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.errorText {
    color: white;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    padding: 20px;
    border-radius: 10px;
}