.wrapper {
    display: flex;
    flex-direction: row;
}

.sidebarContainer {
    display: flex;
    flex: 1.5;
    height: 100vh;
}

.mainContent {
    display: flex;
    flex-direction: column;
    flex: 8.5;
    height: 100vh;
    padding: 2rem;
}

.photosSection {
    display: flex;
    width: 100%;
    height: 300px;
    border: 1px solid black;
    border-radius: 10px;
}

.photosSection img {
    border-radius: 10px;
}

.logo {
    width: 10rem;
    height: 10rem;
    border: 1px solid black;
    position: absolute;
    margin-top: 2rem;
    margin-left: 2rem;
    padding: 1rem;
    border-radius: 10px;
    background: white;
    object-fit: contain;
}

.coverPhoto {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 70%;
}

.nameAndDescSection {
    border: 1px solid black;
    margin-top: 1rem;
    border-radius: 10px;
    padding: 1rem;
}

.addressSection {
    display: flex;
    border: 1px solid black;
    margin-top: 1rem;
    border-radius: 10px;
}

.addressSection .textAddress {
    flex: 5;
    padding: 1rem;
}

.addressSection .geoLocPhoto {
    flex: 2;
}

.addressSection .geoLocPhoto img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0 10px 10px 0;
}