.couponsPage {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.couponForm .formGroup {
    margin-bottom: 15px;
}

.couponForm label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.couponForm input,
.couponForm textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.couponForm textarea {
    resize: vertical;
}

.couponForm button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.couponForm button:hover {
    background-color: #0056b3;
}
