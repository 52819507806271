/* style.module.css */

.sidebar {
    width: 100%;
    background-color: #333;
    color: #fff;
    height: 100%;
    top: 0;
    left: 0;
    padding: 20px;
}

.sidebar ul {
    list-style: none;
    padding: 0;
}

.sidebar li {
    margin: 10px 0;
}

.sidebar a {
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    transition: color 0.3s;
}

.sidebar a:hover {
    color: #ff9900;
    /* Change this to your desired hover color */
}

.sidebar .active {
    color: #ff9900;
    /* Change this to your desired active link color */
    font-weight: bold;
}