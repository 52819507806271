/* Modal Overlay */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal Container */
.modal {
    background: #fff;
    padding: 20px 30px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    width: 80%;
    max-width: 600px;
    position: relative;
    animation: fadeIn 0.3s ease-in-out;
}

/* Fade-in animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

/* Close Button */
.closeButton {
    position: absolute;
    top: 15px;
    right: 15px;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #333;
}

/* Modal Header */
.modal h2 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 1.75rem;
    font-weight: bold;
    text-align: center;
    color: #333;
}

/* Table */
.modal table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.modal table th, .modal table td {
    border: 1px solid #e0e0e0;
    padding: 12px 15px;
    text-align: left;
}

.modal table th {
    background-color: #f9f9f9;
    font-weight: bold;
    color: #555;
}

.modal table td {
    color: #555;
}

.modal table tr:nth-child(even) {
    background-color: #f2f2f2;
}

/* Buttons */
.modal .btn {
    margin: 5px;
    padding: 8px 12px;
    border-radius: 5px;
    font-size: 0.9rem;
}

.modal .btn-success {
    background-color: #28a745;
    color: #fff;
    border: none;
}

.modal .btn-danger {
    background-color: #dc3545;
    color: #fff;
    border: none;
}

.modal .btn-primary {
    background-color: #007bff;
    color: #fff;
    border: none;
}

.modal .btn-success:hover,
.modal .btn-danger:hover,
.modal .btn-primary:hover {
    opacity: 0.9;
}

/* Create New Campaign Button */
.modal .createCampaignBtn {
    display: block;
    margin: 20px auto 0;
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
}

.modal .createCampaignBtn:hover {
    opacity: 0.9;
}
