/* style.module.css (or .css if not using CSS Modules) */

.wrapper {
    display: flex;
    flex-direction: row;
}

.coverPhotoContainer {
    display: flex;
    flex: 6.5;
    height: 500px;
    background: url("https://ak-d.tripcdn.com/images/0ww2w12000acjhzgc5F08.jpg?proc=source%2Ftrip");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
}

.loginContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 3.5;
    height: 500px;
    height: 100vh;
}

.errorMessage {
    color: red;
}

/* Style for form elements */

form {
    width: 85%;
    border: 1px solid black;
    border-radius: 20px;
    padding: 3rem;
}

.form-group {
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 8px;
}

input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 12px 20px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
}

button:hover {
    background-color: #0056b3;
}