.container {
    display: flex;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.loginContainer {
    padding: 20px;
    border-radius: 10px;
    align-items: center;
}

.title {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: bold;
}

.input {
    width: 100%;
    height: 40px;
    border: 1px solid gray;
    border-radius: 5px;
    margin-bottom: 10px;
}

.loginButton {
    background-color: #f16623;
    color: white;
    border-radius: 5px;
    height: 48px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.loginButtonText {
    color: white;
    font-size: 16px;
    font-weight: bold;
}

.errorModal {
    flex: 1;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
}

.errorText {
    color: white;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    padding: 20px;
    border-radius: 10px;
}