/* style.module.css */
.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.viewPager {
    flex: 1;
    height: 350px;
    width: 100%;
}

.page {
    justify-content: center;
    height: 350px;
    width: 100%;
    align-items: center;
}

.photo {
    width: 100%;
    height: 350px;
    object-fit: contain;
}

.container {
    display: flex;
    flex-direction: column;
    background-color: #fff;
}

.contentContainer {
    background-color: #fff;
}

.wrapper {
    width: 92%;
    margin-left: auto;
    margin-right: auto;
}

.businessName {
    font-size: 40px;
    font-weight: bold;
    margin-left: 10px;
    margin-top: 10px;
}

.subsectionHeading {
    font-size: 18px;
    font-weight: bold;
    margin-left: 10px;
    margin-top: 10px;
}

.description {
    font-size: 16px;
    margin-left: 10px;
    margin-top: 10px;
}

.contact {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    margin-top: 10px;
}

.contactItem {
    font-size: 16px;
}

.address {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    margin-top: 10px;
}

.addressText {
    font-size: 16px;
    margin-left: 10px;
}

.map {
    width: 98%;
    height: 150px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    object-fit: cover;
    margin-bottom: 10px;
    border-radius: 15px;
}

.socialMedia {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    margin-top: 10px;
}

a {
    color: black;
    text-decoration: none;
}

.socialMediaIcon {
    border: 1px solid black;
    width: 60px;
    height: 60px;
    font-size: 30px;
    text-align: center;
    line-height: 60px;
    border-radius: 30px;
    margin-right: 10px;
    margin-bottom: 10px;
}