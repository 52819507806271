/* style.module.css */

.wrapper {
    display: flex;
    flex-direction: row;
}

.sidebarContainer {
    flex: 1.5;
    height: 100vh;
}

.mainContent {
    flex: 8.5;
    height: 100vh;
    padding: 2rem;
}

.campaignTable {
    width: 100%;
    border-collapse: collapse;
}

.campaignTable th,
.campaignTable td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.campaignTable th {
    background-color: #f2f2f2;
}

.header {
    margin-bottom: 1rem;
}