.wrapper {
    display: flex;
    flex-direction: row;
}

.sidebarContainer {
    display: flex;
    flex: 1.5;
    height: 100vh;
}

.mainContent {
    display: flex;
    flex-direction: column;
    flex: 8.5;
    height: 100vh;
    padding: 2rem;
}

input,
select {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
}

button:hover {
    background-color: #0056b3;
}

