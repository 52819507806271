.container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    text-align: center;
}

.registerContainer {
    padding: 20px;
    border-radius: 10px;
    align-items: center;
}

.title {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: bold;
}

.input {
    width: 100%;
    height: 40px;
    border-color: gray;
    border-width: 1px;
    border-radius: 5px;
    padding-left: 10px;
    margin-bottom: 10px;
}

.uploadButton {
    background-color: #007bff;
    border-radius: 5px;
    height: 48px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    margin-top: 10px;
}

.uploadButtonText {
    color: white;
    font-size: 16px;
    font-weight: bold;
}

.studentCardImage {
    width: 100%;
    height: 200px;
    object-fit: contain;
    margin-bottom: 10px;
}

.blackBtn {
    background-color: #f16623;
    color: #ffffff;
    border-radius: 5px;
    height: 48px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    margin-top: 10px;
}

.registerButtonText {
    color: white;
    font-size: 16px;
    font-weight: bold;
}

.loginLink {
    color: #007bff;
    margin-top: 10px;
    text-decoration-line: underline;
}

.privacyText {
    align-self: flex-start;
    margin-top: 5px;
}

.checkIcon {
    margin-left: 10px;
}

.dateContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.modalContainer {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* centralize vertically */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.modalText {
    font-size: 22px;
    margin-bottom: 20px;
    color: white;
    text-align: center;
}